import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

export function CountDown({
  endDate = '2022-11-25T00:00:00',
  showLegend = true,
  variant = 'light',
  fontSize = 'text-xl',
  legendFontSize = 'text-md',
  onFinish = () => {}
}) {
  const [hours, setHours] = useState({
    hours: '00',
    minutes: '00',
    seconds: '00'
  });

  useEffect(() => {
    const addZero = str => str.padStart(2, '0');

    const preventNegative = value => Math.max(value, 0);

    const prepareNumber = value => addZero(preventNegative(value).toString());

    const dateStr = endDate.replace(' ', 'T');
    const countDownDate = new Date(dateStr).getTime();
    const countDownClock = setInterval(function () {
      // Get today's date and time
      const now = new Date().getTime();

      // Find the distance between now and the count down date
      const distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      const hours = Math.floor(distance / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // If the count down is over, write some text
      if (distance < 0) {
        onFinish();
        clearInterval(countDownClock);
      }

      setHours({
        hours: prepareNumber(hours),
        minutes: prepareNumber(minutes),
        seconds: prepareNumber(seconds)
      });
    }, 250);

    return () => clearInterval(countDownClock);
  }, [endDate, onFinish]);

  return (
    <div
      className={classNames(
        { '': variant === 'light' },
        { 'bg-primary-darkest': variant === 'dark' },
        'flex flex-col justify-center items-center'
      )}
    >
      <div className="flex flex-col">
        <div className="flex justify-center gap-1 items-center md:justify-between">
          <div
            className={classNames(
              'border-0 rounded-md',
              { 'bg-gray-lightest': variant === 'light' },
              { 'bg-primary-medium': variant === 'dark' }
            )}
          >
            <div className="flex align-center mr-1">
              {hours.hours.split('').map(x => {
                return (
                  <>
                    <div
                      className={classNames(
                        `w-[30px] text-center m-1 mr-0 font-bold p-1 border-0 rounded-md ${fontSize}`,
                        {
                          'bg-white-lightest text-primary-dark':
                            variant === 'light'
                        },
                        { 'bg-primary-dark text-white': variant === 'dark' }
                      )}
                    >
                      {x}
                    </div>
                  </>
                );
              })}
            </div>
            {showLegend && (
              <div
                className={classNames(
                  `font-bold text-center ${legendFontSize}`,
                  { 'text-primary-dark': variant === 'light' },
                  { 'text-white': variant === 'dark' }
                )}
              >
                HORAS
              </div>
            )}
          </div>
          <div className=" text-white font-bold text-[20px]">:</div>
          <div
            className={classNames(
              'border-0 rounded-md',
              { 'bg-gray-lightest': variant === 'light' },
              { 'bg-primary-medium': variant === 'dark' }
            )}
          >
            <div className="flex align-center mr-1">
              {hours.minutes.split('').map(x => {
                return (
                  <>
                    <div
                      className={classNames(
                        `w-[30px] text-center m-1 mr-0 font-bold p-1 border-0 rounded-md ${fontSize}`,
                        {
                          'bg-white-lightest text-primary-dark':
                            variant === 'light'
                        },
                        { 'bg-primary-dark text-white': variant === 'dark' }
                      )}
                    >
                      {x}
                    </div>
                  </>
                );
              })}
            </div>
            {showLegend && (
              <div
                className={classNames(
                  `font-bold text-center ${legendFontSize}`,
                  { 'text-primary-dark': variant === 'light' },
                  { 'text-white': variant === 'dark' }
                )}
              >
                MIN
              </div>
            )}
          </div>
          <div className=" text-white font-bold text-[20px]">:</div>
          <div
            className={classNames(
              'border-0 rounded-md',
              { 'bg-gray-lightest': variant === 'light' },
              { 'bg-primary-medium': variant === 'dark' }
            )}
          >
            <div className="flex align-center mr-1">
              {hours.seconds.split('').map(x => {
                return (
                  <>
                    <div
                      className={classNames(
                        `w-[30px] text-center m-1 mr-0 font-bold p-1 border-0 rounded-md ${fontSize}`,
                        {
                          'bg-white-lightest text-primary-dark':
                            variant === 'light'
                        },
                        { 'bg-primary-dark text-white': variant === 'dark' }
                      )}
                    >
                      {x}
                    </div>
                  </>
                );
              })}
            </div>
            {showLegend && (
              <div
                className={classNames(
                  `font-bold text-center ${legendFontSize}`,
                  { 'text-primary-dark': variant === 'light' },
                  { 'text-white': variant === 'dark' }
                )}
              >
                SEG
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
